import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";

const Account = props => (
  <tr>
    <td>
      <Link to={"/account/"+props.account._id+"/home/"}>{props.account.accountname}</Link><br />
      <small>{props.account.accounturl}</small>
      <Can
        role={props.role}
        perform="accounts:boost"
        yes={() => (<div className="d-block d-lg-none"><small><b>Boost Fulfilled / Total:</b> {props.monthlyBlogCount} / {props.blogCount} - {props.completion}</small></div>)}
        no={() => (null)}
      />
    </td>
    <td className="d-none d-lg-table-cell">{props.account.user}</td>
    <td className="d-none d-lg-table-cell">
      {props.account.lastCrawl}
    </td>
    <Can
      role={props.role}
      perform="accounts:boost"
      yes={() => (<td className="d-none d-lg-table-cell">{props.monthlyBlogCount} / {props.blogCount}<br /><small>{props.completion}</small></td>)}
      no={() => (null)}
    />
    <td className="table-buttons">
      <Can
        role={props.role}
        perform="accounts:edit"
        yes={() => (<Link className="btn btn-primary" to={"/account/"+props.account._id+"/edit/"}>edit</Link>)}
        no={() => (null)}
      />
      <Can
        role={props.role}
        perform="accounts:content"
        yes={() => (<Link className="btn btn-primary" to={props.account._id+"/content/"}>content</Link>)}
        no={() => (null)}
      />
      <Can
        role={props.role}
        perform="accounts:content"
        yes={() => (<Link className="btn btn-primary" to={props.account._id+"/content-existing/"}>rewrites</Link>)}
        no={() => (null)}
      />
      <Can
        role={props.role}
        perform="accounts:boost"
        yes={() => (<Link className="btn btn-primary" to={props.account._id+"/boost/"}>boost</Link>)}
        no={() => (null)}
      />
      <Can
        role={props.role}
        perform="accounts:crawl"
        yes={() => (<button className="btn btn-primary" onClick={() => { props.crawlAccount(props.account._id)}}>crawl</button>)}
        no={() => (null)}
      />
    </td>
  </tr>
)

class AccountList extends Component {
  constructor(props) {
    super(props);

    // Get initial search value from URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const initialSearch = urlParams.get('q') || '';

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];

    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    }

    this.state = {
      accounts: [],
      role: role,
      user: user.email,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false,
      limit: 50,
      skip: 0,
      loadMoreButtonClass: "btn btn-primary",
      search: initialSearch
    };

    this.deleteAccount = this.deleteAccount.bind(this);
    this.crawlAccount = this.crawlAccount.bind(this);
    this.search = this.search.bind(this);
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    return {
      headers: { Authorization: "Bearer " + token }
    };
  }

  async componentDidMount() {
    document.title = "accounts - searchboss";

    // Check for search parameter and perform search if present
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get('q');

    if (searchQuery) {
      await this.performSearch(searchQuery);
    } else {
      await this.loadAccounts();
    }
  }

  // Listen for browser navigation events
  componentDidUpdate(prevProps) {
    if (window.location.search !== prevProps.location?.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const searchQuery = urlParams.get('q') || '';

      if (searchQuery !== this.state.search) {
        this.setState({ search: searchQuery }, () => {
          if (searchQuery) {
            this.performSearch(searchQuery);
          } else {
            this.loadAccounts();
          }
        });
      }
    }
  }

  async loadAccounts() {
    if (this.state.staticRules.includes("accounts:view")) {
      const config = await this.config();
      var limit = this.state.limit;
      var skip = this.state.skip;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/account/?limit=${limit}&skip=${skip}`,
          config
        );

        this.setState({
          accounts: response.data,
          isReady: true,
          loadMoreButtonClass: response.data.length < limit ? "d-none" : "btn btn-primary"
        });

        this.updateCrawlingStatus();
      } catch (error) {
        console.log(error);
        this.setState({ isReady: true });
      }
    } else {
      alert("This access level does not have permission to view accounts. Please contact your administrator.");
    }
  }

  async loadMoreAccounts() {
    if (this.state.staticRules.includes("accounts:view")) {
      const config = await this.config();
      var limit = this.state.limit;
      var skip = this.state.skip + 50;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/account/?limit=${limit}&skip=${skip}`,
          config
        );

        const accounts = this.state.accounts.concat(response.data);

        this.setState({
          accounts: accounts,
          isReady: true,
          skip: skip,
          loadMoreButtonClass: response.data.length < limit ? "d-none" : "btn btn-primary"
        });

        this.updateCrawlingStatus();
      } catch (error) {
        console.log(error);
        alert(error);
      }
    } else {
      alert("This access level does not have permission to view accounts. Please contact your administrator.");
    }
  }

  LoadMoreAccountsButton() {
    return (
      <button
        className={this.state.loadMoreButtonClass}
        onClick={() => this.loadMoreAccounts()}
      >
        load more accounts
      </button>
    );
  }

  async performSearch(searchQuery) {
    if (!this.state.staticRules.includes("accounts:view")) {
      alert("This access level does not have permission to view accounts. Please contact your administrator.");
      return;
    }

    this.setState({ isReady: false });

    try {
      const config = await this.config();
      const response = await axios.get(
        `${process.env.REACT_APP_API}/account/search?search=${searchQuery}`,
        config
      );

      this.setState({
        accounts: response.data,
        loadMoreButtonClass: "d-none",
        isReady: true
      });

      this.updateCrawlingStatus();

      // Update URL without reloading the page
      const newUrl = searchQuery
        ? `${window.location.pathname}?q=${encodeURIComponent(searchQuery)}`
        : window.location.pathname;

      window.history.pushState(
        { search: searchQuery },
        '',
        newUrl
      );
    } catch (error) {
      console.log(error);
      alert(error);
      this.setState({ isReady: true });
    }
  }

  updateCrawlingStatus() {
    let accounts = [...this.state.accounts];
    accounts.forEach(account => {
      if (account.crawling === true) {
        account.lastCrawl = "Crawl in progress.";
      }
    });
    this.setState({ accounts });
  }

  async search(event) {
    event.preventDefault();
    const searchQuery = this.state.search.trim();
    await this.performSearch(searchQuery);
  }

  async deleteAccount(id) {
    if (this.state.staticRules.includes("accounts:delete")) {
      const config = await this.config();
      const data = null;

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API}/account/delete/${id}`,
          data,
          config
        );

        console.log(response.data);
        if (response.data.crawling === false) {
          this.setState({
            accounts: this.state.accounts.filter(el => el._id !== id)
          });
        }
      } catch (err) {
        alert(err.response.data.message);
      }
    } else {
      alert("This access level does not have permission to delete accounts. Please contact your administrator.");
    }
  }

  async crawlAccount(id) {
    if (this.state.staticRules.includes("accounts:crawl")) {
      const config = await this.config();
      const data = null;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/account/${id}/crawling/`,
          config
        );

        const date = new Date();
        const dateString = date.toDateString();

        if (response.data.crawling === false) {
          if (response.data.lastCrawl === dateString) {
            alert("On-demand crawl already completed today.");
          } else {
            await axios.post(
              `${process.env.REACT_APP_API}/account/crawl/${id}`,
              data,
              config
            );
            console.log("Crawl started!");

            let accounts = [...this.state.accounts];
            let index = accounts.findIndex(el => el._id === id);
            let account = {...accounts[index]};
            account.lastCrawl = "Crawl started!";
            accounts[index] = account;
            this.setState({accounts});
          }
        } else {
          alert("Crawl already in progress.");
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("This access level does not have permission to crawl accounts. Please contact your administrator.");
    }
  }

  boostTotal() {
    if (this.state.staticRules.includes("accounts:boost")) {
      let result = this.state.accounts.map(a => a.boostlinks);
      return result.reduce((partialSum, a) => partialSum + a, 0);
    }
  }

  publishedBoostTotal() {
    if (this.state.staticRules.includes("accounts:boost")) {
      const accountsList = this.state.accounts;
      const today = new Date();

      var month = String(today.getMonth() + 1);
      var year = String(today.getFullYear());

      if (month < 10) {
        month = "0" + month;
      }

      let dateFilter = year + "-" + month;
      let publishedLinks = 0;

      accountsList.forEach(currentaccount => {
        var thisMonth = currentaccount.boost.filter((blog) => blog.createdAt.startsWith(dateFilter));
        publishedLinks += thisMonth.length;
      });

      return publishedLinks;
    }
  }

  accountList(role) {
    const accountsList = this.state.accounts;
    const today = new Date();

    var month = String(today.getMonth() + 1);
    var year = String(today.getFullYear());

    if (month < 10) {
      month = "0" + month;
    }

    let dateFilter = year + "-" + month;

    return accountsList.map(currentaccount => {
      let blogCount = 0;
      let monthlyBlogCount = 0;
      let completion = "N/A";

      if (this.state.staticRules.includes("accounts:boost")) {
        blogCount = currentaccount.boostlinks;
        const monthlyBlogs = currentaccount.boost.filter((blog) => blog.createdAt.startsWith(dateFilter));
        monthlyBlogCount = monthlyBlogs.length;

        const percentage = (monthlyBlogCount / blogCount) * 100;
        completion = Math.round(percentage) + "%";

        if (completion === "NaN%" || completion === "Infinity%") {
          completion = "N/A";
        }
      }

      return (
        <Account
          account={currentaccount}
          deleteAccount={this.deleteAccount}
          crawlAccount={this.crawlAccount}
          blogCount={blogCount}
          monthlyBlogCount={monthlyBlogCount}
          completion={completion}
          role={role}
          key={currentaccount._id}
        />
      );
    });
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-lg-7 text-xl-left">
            <h3>Accounts</h3>
          </div>
          <div className="col-lg-5 text-xl-right">
            <Can
              role={this.state.role}
              perform="accounts:boost"
              yes={() => (
                <div>
                  <b>Total Boost Monthly Links:</b> { this.boostTotal() }<br />
                  <b>Total Published Links This Month:</b> { this.publishedBoostTotal() }
                </div>
                )}
              no={() => (null)}
            />
            <form className="form-group search-bar" onSubmit={a => this.search(a)}>
              <input className="form-control" type="search" placeholder="search for account name" onChange={event => this.setState({search: event.target.value})}></input>
            </form>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead className="thead-light">
              <tr>
                <th>Account Name</th>
                <th className="d-none d-lg-table-cell">User</th>
                <th className="d-none d-lg-table-cell">Last Crawl</th>
                <Can
                  role={this.state.role}
                  perform="accounts:boost"
                  yes={() => (<th className="d-none d-lg-table-cell">Boost <small>Month / Demand</small></th>)}
                  no={() => (null)}
                />
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              { this.accountList(this.state.role) }
            </tbody>
          </table>
        </div>
        <div className="text-center more-accounts-button">
          { this.LoadMoreAccountsButton() }
        </div>
      </div>
    )
  }
}

export default withAuth0(AccountList);
